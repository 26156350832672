.Tag.card-columns {
    column-count: 1;
}
.Tag .card-body .table tr:first-child td {
    border-top: 0;
}
@media (min-width:1200px) {
    .Tag.card-columns {
        column-count: 2;
    }
}
