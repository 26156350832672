.PeriodMatrixItems table {
    width: 100%;
}
.PeriodMatrixItems .card .card-header {
    padding: 0;
}
.PeriodMatrixItems .card .card-header-input {
    padding: 3px;
}
.PeriodMatrixItems .card .card-header-period {
    position: relative;
}
.PeriodMatrixItems .card .card-header-period .button-remove {
    display: none;
    position: absolute;
    right: 3px;
    top: 3px;
}
.PeriodMatrixItems .card .card-header-period:hover .button-remove {
    display: block;
}
.PeriodMatrixItems .card .card-body {
    padding: .5rem;
}
