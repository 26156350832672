.MatrixItem table {
    width: 100%;
}
.MatrixItem .text-readonly {
    color: #999;
}
.MatrixItem .button-cell {
    white-space: nowrap;
    width: 0.1%;
}
