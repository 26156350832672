.DailyReport .navbar-expand .navbar-collapse {
    display: block !important;
}
.DailyReport .report-title {
    display: inline-block;
    margin: 8px 0 15px;
    white-space: nowrap;
}
.DailyReport .card-columns {
    column-count: 1;
}
.DailyReport .card-body .table tr:first-child td {
    border-top: 0;
}
@media (min-width:1200px) {
    .DailyReport .card-columns {
        column-count: 2;
    }
    .DailyReport .card-columns.columns-1 {
        column-count: 1;
    }
}
.DailyReport .toast {
    max-width: 100%;
}
