.ProductChangesList .list-group-item {
    cursor: pointer;
}
.ProductChangesList .card-header {
    position: relative;
}
.ProductChangesList .card-header .btn {
    margin-top: -3px;
    position: relative;
    z-index: 20;
}
