.Producer.card-columns {
    column-count: 1;
}
.Producer .card-body .table tr:first-child td {
    border-top: 0;
}
@media (min-width:1200px) {
    .Producer.card-columns {
        column-count: 2;
    }
}
