.Tooltip.tooltip-primary.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #007bff;
}
.Tooltip.tooltip-primary.bs-tooltip-left .arrow:before {
    border-left-color: #007bff;
}
.Tooltip.tooltip-primary.bs-tooltip-right .arrow:before {
    border-right-color: #007bff;
}
.Tooltip.tooltip-primary.bs-tooltip-top .arrow:before {
    border-top-color: #007bff;
}
.Tooltip.tooltip-primary .tooltip-inner {
    background-color: #007bff;
}

.Tooltip.tooltip-secondary.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #6c757d;
}
.Tooltip.tooltip-secondary.bs-tooltip-left .arrow:before {
    border-left-color: #6c757d;
}
.Tooltip.tooltip-secondary.bs-tooltip-right .arrow:before {
    border-right-color: #6c757d;
}
.Tooltip.tooltip-secondary.bs-tooltip-top .arrow:before {
    border-top-color: #6c757d;
}
.Tooltip.tooltip-secondary .tooltip-inner {
    background-color: #6c757d;
}

.Tooltip.tooltip-success.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #28a745;
}
.Tooltip.tooltip-success.bs-tooltip-left .arrow:before {
    border-left-color: #28a745;
}
.Tooltip.tooltip-success.bs-tooltip-right .arrow:before {
    border-right-color: #28a745;
}
.Tooltip.tooltip-success.bs-tooltip-top .arrow:before {
    border-top-color: #28a745;
}
.Tooltip.tooltip-success .tooltip-inner {
    background-color: #28a745;
}

.Tooltip.tooltip-warning.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #ffc107;
}
.Tooltip.tooltip-warning.bs-tooltip-left .arrow:before {
    border-left-color: #ffc107;
}
.Tooltip.tooltip-warning.bs-tooltip-right .arrow:before {
    border-right-color: #ffc107;
}
.Tooltip.tooltip-warning.bs-tooltip-top .arrow:before {
    border-top-color: #ffc107;
}
.Tooltip.tooltip-warning .tooltip-inner {
    background-color: #ffc107;
}

.Tooltip.tooltip-danger.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #dc3545;
}
.Tooltip.tooltip-danger.bs-tooltip-left .arrow:before {
    border-left-color: #dc3545;
}
.Tooltip.tooltip-danger.bs-tooltip-right .arrow:before {
    border-right-color: #dc3545;
}
.Tooltip.tooltip-danger.bs-tooltip-top .arrow:before {
    border-top-color: #dc3545;
}
.Tooltip.tooltip-danger .tooltip-inner {
    background-color: #dc3545;
}

.Tooltip.tooltip-info.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #17a2b8;
}
.Tooltip.tooltip-info.bs-tooltip-left .arrow:before {
    border-left-color: #17a2b8;
}
.Tooltip.tooltip-info.bs-tooltip-right .arrow:before {
    border-right-color: #17a2b8;
}
.Tooltip.tooltip-info.bs-tooltip-top .arrow:before {
    border-top-color: #17a2b8;
}
.Tooltip.tooltip-info .tooltip-inner {
    background-color: #17a2b8;
}

.Tooltip.tooltip-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #f8f9fa;
}
.Tooltip.tooltip-light.bs-tooltip-left .arrow:before {
    border-left-color: #f8f9fa;
}
.Tooltip.tooltip-light.bs-tooltip-right .arrow:before {
    border-right-color: #f8f9fa;
}
.Tooltip.tooltip-light.bs-tooltip-top .arrow:before {
    border-top-color: #f8f9fa;
}
.Tooltip.tooltip-light .tooltip-inner {
    background-color: #f8f9fa;
}

.Tooltip.tooltip-dark.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #343a40;
}
.Tooltip.tooltip-dark.bs-tooltip-left .arrow:before {
    border-left-color: #343a40;
}
.Tooltip.tooltip-dark.bs-tooltip-right .arrow:before {
    border-right-color: #343a40;
}
.Tooltip.tooltip-dark.bs-tooltip-top .arrow:before {
    border-top-color: #343a40;
}
.Tooltip.tooltip-dark .tooltip-inner {
    background-color: #343a40;
}

.Tooltip.bs-tooltip-bottom .arrow,
.Tooltip.bs-tooltip-top .arrow {
    left: calc(50% - .4rem);
}
.Tooltip.bs-tooltip-left .arrow,
.Tooltip.bs-tooltip-right .arrow {
    top: calc(50% - .4rem);
}
