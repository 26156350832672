.Devices.card-columns {
    column-count: 1;
}
@media (min-width:1200px) {
    .Devices.card-columns {
        column-count: 2;
    }
}
.Devices .toast {
    max-width: 100%;
}
