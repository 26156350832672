.ProductUnit.card-columns {
    column-count: 1;
}
.ProductUnit .card-body .table tr:first-child td {
    border-top: 0;
}
.ProductUnit .toast-body p {
    margin-bottom: 0.2rem;
}
.ProductUnit .toast-body p:last-child {
    margin-bottom: 0;
}
@media (min-width:1200px) {
    .ProductUnit.card-columns {
        column-count: 2;
    }
}
