.ResourceShow .toast-header {
    padding: .25rem;
}
.ResourceShowModal .modal-dialog {
    height: calc(100% - 1rem);
    margin: 0.5rem;
    max-width: none;
    width: calc(100% - 1rem);
}
.ResourceShowModal .modal-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
}
.ResourceShowModal .modal-header {
    flex-grow: 0;
    flex-shrink: 1;
}
.ResourceShowModal .spinner-border {
    margin: auto;
}
.ResourceShowModal iframe {
    border: 0;
    border-top: #343a40 solid 1px;
    flex-grow: 1;
    flex-shrink: 0;
}
