.InventoryWide.card-columns,
.InventoryProducts.card-columns {
    column-count: 1;
}
@media (min-width:1200px) {
    .InventoryProducts.card-columns {
        column-count: 2;
    }
}
.InventoryProducts .toast {
    max-width: 100%;
    cursor: pointer;
}
.InventoryWide .borderless td {
    border: 0;
}
.InventoryWide .card-header .btn-link {
    padding: 0;
}
