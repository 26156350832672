.Rentals.card-columns {
    column-count: 1;
}
.Rentals .card-body .table tr:first-child td {
    border-top: 0;
}
.Rentals .toast-body p {
    margin-bottom: 0.2rem;
}
.Rentals .toast-body p:last-child {
    margin-bottom: 0;
}
@media (min-width:1200px) {
    .Rentals.card-columns {
        column-count: 2;
    }
}
