.ProductChangesControls.card-columns,
.ProductChangesLists.card-columns {
    column-count: 1;
}
@media (min-width:1200px) {
    .ProductChangesLists.card-columns {
        column-count: 2;
    }
}
.ProductChangesLists .toast {
    max-width: 100%;
}
