.ProductTags .card-header .btn {
    float: right;
    margin: -3px 0 -3px 5px;
}
.ProductTags .card-body .badge {
    cursor: pointer;
}
.ProductTags .card-body .btn {
    margin: 2px;
}
.ProductTags .card-body .btn a {
    color: #fff;
}
