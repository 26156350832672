.Header .gravatar-thumbnail {
    height: 36px;
    margin: 2px;
    padding: 1px;
    width: 36px;
}
.Header .history-back {
    border-right: 1px solid #6c757d;
    color: #fff;
    cursor: pointer;
    float: left;
    margin-right: 13px;
    padding: 10px 13px;
}
