.card-header.card-opened {
    cursor: pointer;
}
.card-header.card-closed {
    cursor: pointer;
}
.card-header.card-opened:after,
.card-header.card-closed:after {
    border: 6px solid transparent;
    content: '';
    display: block;
    position: absolute;
}
.card-header.card-opened:after {
    border-top-color: #000;
    right: 20px;
    top: 21px;
}
.card-header.card-closed:after {
    border-right-color: #000;
    right: 23px;
    top: 18px;
}

.card-body > :last-child {
    margin-bottom: 0;
}
